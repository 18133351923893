import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Programming Resources`}</h1>
    <br />
    <h2>{`Modern Javascript Fundamentals`}</h2>
    <h3>{`Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ryanmcdermott/clean-code-javascript"
        }}>{`Clean Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/getify/You-Dont-Know-JS"
        }}>{`You Don't Know JS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.30secondsofcode.org/"
        }}>{`30 seconds of code`}</a></li>
    </ul>
    <h3>{`Articles`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codeburst.io/es6-destructuring-the-complete-guide-7f842d08b98f"
        }}>{`Destructuring: The Complete Guide`}</a></li>
    </ul>
    <h3>{`Videos`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=rRgD1yVwIvE"
        }}>{`Traversy - JavaScript Higher Order Functions & Arrays`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=yfoY53QXEnI"
        }}>{`Traversy - CSS Crash Course For Absolute Beginners`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=UBOj6rqRUME"
        }}>{`Traversy - Tailwind CSS Crash Course`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=Pg_5Ni1_bg4"
        }}>{`Tailwind CSS - Rebuilding Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=w7ejDZ8SWv8"
        }}>{`React Crash Course 2021`}</a></li>
    </ul>
    <h2>{`Other`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
        }}>{`Markdown Cheatsheet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tailwindcss.com/"
        }}>{`Tailwind CSS`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      